import React from 'react';
import CompanyReducer, { defaultState } from './company-reducer';
import { Company } from '../../types';
import actions from "./company-actions";

type CompanyProviderProps = { children: React.ReactNode };

export interface CompanyContextData {
    company: { company_token: string }
    loadCompany: (company: Company) => void,
}

const CompanyContextDefaultValue: CompanyContextData = {
    company: { company_token: '' },
    loadCompany: () => null,
};

const CompanyContext = React.createContext<CompanyContextData>(CompanyContextDefaultValue);

function useCompanyContextValue() {
    const [state, dispatch] = React.useReducer(CompanyReducer, defaultState);

    const { company} = state;

    const loadCompany = React.useCallback((company: Company) => dispatch(actions.loadCompany(company)), []);

    return React.useMemo(() => {
        return {
            company, loadCompany,
        };
    }, [
        company, loadCompany,
    ]);
}

function CompanyProvider({ children }: CompanyProviderProps) {
    const value = useCompanyContextValue();
    return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
}

function useCompany(): CompanyContextData {
    const context = React.useContext(CompanyContext);
    if (!context) {
        throw new Error('useCompany must be used within an CompanyProvider');
    }
    return context;
}

export { useCompany, CompanyProvider };
