import React from 'react';
import { BsCartCheckFill } from "react-icons/bs";

const AddedToCartIcon = () => {
    return (
        <BsCartCheckFill className="text-green-500" title="Added to the request" />
    );
};

export default AddedToCartIcon;
