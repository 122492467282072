import { API } from "./api_config";

// const COMPANY = process.env.REACT_APP_COMPANY;

function doPostOrder(payload: any, token: string) {
    return API.post(`/v1/expressleather/companies/${token}/orders`, payload);
}

export {
    doPostOrder,
}
