import React from "react";
import CartReducer, { defaultState } from "./cart-reducer";
import actions from "./cart-actions";
import { Article, CartCategory as Category, CartItem, IndexedItem } from "../../types";

interface CartContextData {
    cart: Array<CartItem>,
    addToCart: (article: IndexedItem, color: IndexedItem) => void,
    removeFromCart: (article: IndexedItem, color: IndexedItem) => void,
    changeItemAmount: (article: IndexedItem, color: IndexedItem, amount: number) => void,
    loadCart: (cart: Array<CartItem>) => void,
    currentArticle: Article | null,
    setCurrentArticle: (article: Article) => void,
    restructureCart: () => Category[],
    emptyCart: () => void,
}

const defaultCartContextData: CartContextData = {
    cart: [],
    addToCart: () => null,
    removeFromCart: () => null,
    changeItemAmount: () => null,
    loadCart: () => null,
    currentArticle: null,
    setCurrentArticle: () => null,
    restructureCart: () => [],
    emptyCart: () => null,

}

const CartContext = React.createContext<CartContextData>(defaultCartContextData);

function useCartContextValue() {

    const [state, dispatch] = React.useReducer(CartReducer, defaultState);

    const { cart, currentArticle } = state;

    const addToCart = React.useCallback((article: IndexedItem, color: IndexedItem) => dispatch(actions.addToCart(article, color)), []);
    const removeFromCart = React.useCallback((article: IndexedItem, color: IndexedItem) => dispatch(actions.removeFromCart(article, color)), []);
    const loadCart = React.useCallback((cart: Array<CartItem>) => dispatch(actions.loadCart(cart)), []);
    const setCurrentArticle = React.useCallback((article: Article) => dispatch(actions.setCurrentArticle(article)), []);
    const changeItemAmount = React.useCallback((article: IndexedItem, color: IndexedItem, amount: number) => dispatch(actions.changeItemAmount(article, color, amount)), []);
    const emptyCart = React.useCallback(() => dispatch(actions.emptyCart()), []);

    const restructureCart = () => {
        const categories: any = {};

        cart.forEach((item: CartItem) => {
            const index = 'art-' + item.article.id;
            if (!categories[index]) {
                categories[index] = {
                    article: item.article,
                    items: [item],
                }
            } else {
                categories[index].items = [...categories[index].items, item]
            }
        });

        return Object.values(categories) as Category[];
    }

    return React.useMemo(() => {
        return {
            cart, addToCart, removeFromCart, loadCart, changeItemAmount,
            currentArticle, setCurrentArticle,
            restructureCart, emptyCart
        }
    }, [
        cart, addToCart, removeFromCart, loadCart, changeItemAmount,
        currentArticle, setCurrentArticle,
        restructureCart, emptyCart
    ]);
}

type CartProviderProps = { children: React.ReactNode };

function CartProvider({ children }: CartProviderProps) {

    const value = useCartContextValue();

    React.useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(value.cart));
    }, [value.cart]);

    return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

function useCart() {
    const context = React.useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
}

export {
    CartProvider,
    useCart,
}
