import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useCart} from "../utils/cart-context/cart-context";
import useApi from "../utils/custom_hooks/api-hook";
import {doGetArticle} from "../logics/articles";
import Layout from "../components/Ui/website_structure/Layout";
import Loading from "../components/Ui/Loading";
import ArticleDetails from "../components/Article/ArticleDetails";
import Colors from "../components/Article/Colors";
import ArticleCart from "../components/Article/ArticleCart";
import BackLink from "../components/Ui/BackLink";
import MaxWidthDiv from "../components/Ui/website_structure/MaxWidthDiv";
import {useCompany} from "../utils/company-context/company-context";
import {getDomain} from '../utils/helpers';
import {doGetToken} from "../logics/company";

const Article = () => {

    const {id} = useParams();
    const {cart, currentArticle, setCurrentArticle} = useCart();
    const {call, loading} = useApi();

    const {loadCompany, company} = useCompany();

    function isObjectEmpty(obj: Record<string, any>): boolean {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }


    useEffect(() => {

        const currentUrl = window.location.href;
        const domain = getDomain(currentUrl);

        const load = async () => {
            if (isObjectEmpty(company)) {
                await call(
                    () => doGetToken(encodeURI(domain)),
                    (response) => {
                        if (response !== undefined) {
                            if (response) loadCompany(response);
                        }
                    }
                )
            }
        }
        load();
    }, []);

    const filterByCurrentArticle = () => {
        if (currentArticle) {
            return cart.filter(item => item.article.id === currentArticle.id);
        } else {
            return [];
        }
    }

    useEffect(() => {
        const load = async () => {
            await call(
                () => doGetArticle(id, company.company_token),
                (res) => {
                    if (res.articles && res.articles.length > 0) {
                        setCurrentArticle(res.articles[0]);
                    }
                }
            )
        }

        load();
    }, [company])

    return (
        <Layout payoffDisplay={true}>
            <MaxWidthDiv>
                <div className="flex flex-row items-center justify-between mb-4">
                    <BackLink className='mx-2 my-6' text={'Back'}
                              to={'/'}/>
                    {currentArticle &&
                        <div className="px-4 sm:text-md md:text-2xl">
                            {currentArticle.name}</div>

                    }
                    <BackLink className='mx-2 my-6' text={'Cart'}
                              to={'/cart'}/>
                </div>
            </MaxWidthDiv>
            {loading ?
                <Loading/>
                :
                currentArticle &&
                <div style={{height: 'calc(70vh - 0px)', overflowY: 'auto'}} className='no-scrollbar w-full'>
                    <ArticleDetails/>
                    <MaxWidthDiv className="flex flex-row">
                        {currentArticle.colors && <Colors/>}
                    </MaxWidthDiv>
                </div>
            }
        </Layout>
    );
};

export default Article;
