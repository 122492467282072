import React from 'react';
import {useArticles} from "../../utils/articles-context/articles-context";
import Article from "./Article";
import {AnimatePresence, motion} from "framer-motion";
import {list} from "../animations/animation";

const ArticleList = () => {

    const {filter, articles} = useArticles();

    const getFilteredArticles = () => {
        if (filter)
            return articles.filter(article => article.categories.some(cat => cat === filter))
        else return articles;
    }

    return (
        <div>

            <motion.div variants={list}
                        initial="hidden"
                        animate="visible">
                <AnimatePresence>
                    {articles && getFilteredArticles().length > 0
                        ?
                        <div
                            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 px-4 no-scrollbar"
                            style={{overflowY: 'auto'}}>
                            {
                                getFilteredArticles().map((art, i) =>
                                    <Article article={art} key={i}/>)
                            }
                        </div>
                        :
                        <div className="text-center w-full">No items</div>
                    }
                </AnimatePresence>
            </motion.div>

        </div>
    );
}

export default ArticleList;
