import { CartActionType } from "../reducer-types";
import { Article, CartItem, IndexedItem } from "../../types";

function addToCart(article: IndexedItem, color: IndexedItem) {
    return {
        article,
        color,
        type: CartActionType.ADD_TO_CART
    }
}

function removeFromCart(article: IndexedItem, color: IndexedItem) {
    return {
        article,
        color,
        type: CartActionType.REMOVE_FROM_CART,
    }
}

function loadCart(cart: Array<CartItem>) {
    return {
        cart,
        type: CartActionType.LOAD_CART,
    }
}


function emptyCart() {
    return {
        type: CartActionType.EMPTY_CART,
    }
}


function setCurrentArticle(article: Article) {
    return {
        article,
        type: CartActionType.SET_CURRENT_ARTICLE
    }
}

function changeItemAmount(article: IndexedItem, color: IndexedItem, amount: number) {
    return {
        article,
        color,
        amount,
        type: CartActionType.CHANGE_ITEM_AMOUNT
    }
}

const actions = {
    addToCart,
    removeFromCart,
    loadCart,
    setCurrentArticle,
    changeItemAmount,
    emptyCart
}

export default actions;
