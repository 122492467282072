import {CompanyActionType} from "../reducer-types";
import {Company} from "../../types";

function loadCompany(company: Company) {
    return {
        type: CompanyActionType.LOAD_COMPANY_TOKEN,
        company,
    };
}

const actions = {
    loadCompany,
}

export default actions;

