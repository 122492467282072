import React, {useState, useEffect} from 'react';
import MaxWidthDiv from "./MaxWidthDiv";
import {useNavigate} from "react-router-dom";
import {IoCartOutline} from "react-icons/io5";
import {useCart} from "../../../utils/cart-context/cart-context";
import {motion, useAnimation} from "framer-motion";
import {IoHomeOutline} from "react-icons/io5";
import {getDomain} from '../../../utils/helpers';
import {doGetToken} from '../../../logics/company';
import {doGetCompany} from '../../../logics/company';
import useApi from '../../../utils/custom_hooks/api-hook';
import {useCompany} from '../../../utils/company-context/company-context';
import {ICompany as Company} from "../../../utils/interfaces/ICompany";

const Header = () => {

    const {cart} = useCart();
    const animationControls = useAnimation();
    const [cartUpdated, setCartUpdated] = React.useState(false);
    const {call} = useApi();
    const {loadCompany, company} = useCompany();

    const [myCompany, setMyCompany] = useState<Company>({
        pay_off: '',
        logo_url: '',
        background_color: '#fff',
        font_color: '',
        company_name: '',
        second_pay_off: '',
        second_pay_off_color: '',
        third_pay_off: '',
        third_pay_off_color: '',
    });

    useEffect(() => {

        const currentUrl = window.location.href;
        const domain = getDomain(currentUrl);

        const load = async () => {
            if (!company) {
                await call(
                    () => doGetToken(encodeURI(domain)),
                    (response) => {
                        if (response !== undefined) {
                            if (response) loadCompany(response);
                        }
                    }
                )
            }
        }
        load();
    }, []);

    useEffect(() => {

        const load = async () => {
            if (company.company_token) {
                await call(
                    () => doGetCompany(company.company_token),
                    (response) => {
                        if (response) setMyCompany(response);
                    }
                )
            }

        }

        load();
    }, [company.company_token]);

    React.useEffect(() => {
        if (cart.length > 0) {
            setCartUpdated(true);
            animationControls.start({scale: [1, 1.5, 1], transition: {duration: 0.2}});
        }
    }, [cart, animationControls]);

    const navigate = useNavigate();

    const goBackToHome = () => {
        navigate('/');
    }

    const goToCart = () => {
        navigate('/cart');
    }

    const handleClick = () => {
        navigate('/');
    };

    return (
        <header className="w-full">
            <div className="bg-eBLUE p-2">
                <MaxWidthDiv className="flex flex-row justify-between items-center">
                    <h1 className="text-white font-bold cursor-pointer text-sm md:text-xl"
                        onClick={goBackToHome}>Express Leather</h1>
                    <div className="text-white text-sm md:text-xl">
                        {myCompany && myCompany.company_name.toUpperCase()}
                    </div>
                    <div className="relative flex">
                        <motion.div
                            animate={{scale: [1, 1.5, 1]}}
                            transition={{duration: 0.3}}
                        >
                            <IoHomeOutline style={{width: "25px", height: "25px"}}
                                           className="mr-4 text-white text-xl cursor-pointer" onClick={handleClick}>
                            </IoHomeOutline>
                        </motion.div>

                        <motion.div animate={animationControls}>
                            <IoCartOutline style={{width: "25px", height: "25px"}}
                                           className="text-white text-xl cursor-pointer" onClick={goToCart}/>
                            {cartUpdated && (
                                <motion.div
                                    className="absolute top-0 right-0 bg-red-500 rounded-full"
                                    style={{width: "10px", height: "10px"}}
                                    initial={{scale: 0}}
                                    animate={{scale: 1}}
                                    transition={{duration: 0.2}}
                                />
                            )}
                        </motion.div>
                    </div>
                </MaxWidthDiv>
            </div>

        </header>
    );
};

export default Header;
