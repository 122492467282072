import React from 'react';
import Category from "./Category";
import {useArticles} from "../../utils/articles-context/articles-context";

const Categories = () => {

    const {categories, loadCategories} = useArticles();

    return (
        <div className="flex flex-row flex-wrap gap-2 items-center justify-center mb-4">
            {categories && categories.length > 0
                ? <>
                    <Category value={null} name={"All"}/>
                    {categories.map((cat, i) =>
                        <Category value={cat.id} name={cat.name} key={i}/>)}
                </>
                : <div></div>
            }
        </div>
    );
};

export default Categories;
