import { API } from "./api_config";

// const COMPANY = process.env.REACT_APP_COMPANY;

async function doGetArticles(token: string) {
    return API.get(`/v1/expressleather/companies/${token}/articles/`);
}

async function doGetArticle(id: string | undefined, token: string) {
    return API.get(`/v1/expressleather/companies/${token}/articles/${id}`);
}

export {
    doGetArticles,
    doGetArticle
};
