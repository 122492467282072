import React from 'react';
import {Category as CategoryType} from "../../types";
import {useArticles} from "../../utils/articles-context/articles-context";

type Props = {
    value: number | null,
    name: string,
}

const Category = ({value, name}: Props) => {

    const {filter, setFilter} = useArticles();

    const handleClick = () => {
        setFilter(value);
    }

    return (
        <button onClick={handleClick}
                type="button"
                className={`cursor-pointer text-center min-w-[50px] text-eBLUE300 hover:text-eBLUE p-1 ${filter === value && "font-bold border-b border-eBLUE300"}`}>
            {name}
        </button>
    );
};

export default Category;
