import React, {useEffect, useState,} from 'react';
import Layout from '../components/Ui/website_structure/Layout';
import {doGetArticles} from "../logics/articles";
import {doGetToken} from "../logics/company";
import useApi from "../utils/custom_hooks/api-hook";
import {useArticles} from "../utils/articles-context/articles-context";
import Categories from "../components/Articles/Categories";
import ArticleList from "../components/Articles/ArticleList";
import MaxWidthDiv from "../components/Ui/website_structure/MaxWidthDiv";
import {useCompany} from "../utils/company-context/company-context";
import {getDomain} from '../utils/helpers';
import FurtherPayoffs from "../components/Ui/website_structure/FurtherPayoffs";


const Home = () => {

    const {call, loading} = useApi();
    const {loadArticles, loadCategories} = useArticles();
    const {loadCompany, company} = useCompany();

    useEffect(() => {


        const currentUrl = window.location.href;

        const domain = getDomain(currentUrl);

        const load = async () => {
            if (company && !company.company_token) {
                await call(
                    () => doGetToken(encodeURI(domain)),
                    (response) => {
                        if (response) loadCompany(response);
                    }
                )
            }

        }

        if (domain) {
            load();
        }
    }, []);


    useEffect(() => {
        const getArticles = async () => {
            await call(
                () => doGetArticles(company.company_token),
                (response) => {
                    if (response.articles) loadArticles(response.articles);
                    if (response.categories) loadCategories(response.categories);
                }
            )
        };

        if (company.company_token) {
            getArticles();
        }
    }, [company, company.company_token]);

    return (
        <Layout payoffDisplay={true} furtherPayoffsDisplay={true}>
            {loading ?
                <div>Loading</div>
                :
                <MaxWidthDiv>
                    <>
                        <Categories/>
                        <ArticleList/>
                    </>
                </MaxWidthDiv>
            }
        </Layout>
    );
};

export default Home;
