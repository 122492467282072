import React from 'react';
import Form from "./Form";
import OrderRecap from "./OrderRecap";
import MaxWidthDiv from "../Ui/website_structure/MaxWidthDiv";
import BackLink from "../Ui/BackLink";


const OrderContainer = () => {


    return (
        <MaxWidthDiv className="p-4 ">
            <div className="flex flex-row items-center justify-between mb-4">
                <div className="text-3xl text-eBLUE text-start font-bold mb-4">Sales quotation:</div>
                <BackLink className='my-6' text={'Back'}
                    to={'/cart'} />
            </div>
            <div className="flex flex-col-reverse md:flex-row md:items-start gap-6">
                <Form />
                <OrderRecap />
            </div>
        </MaxWidthDiv>
    );
};

export default OrderContainer;
