import React from 'react';

interface Props {
    label: string,
    name?: string,
    placeholder?: string,
    required?: boolean
}

const LabelledInput = ({ label, name = "", placeholder = "", required = false, ...props }: Props) => {
    return (
        <div className="w-full">
            <div className="text-sm text-eBLUE300 mb-1">{label}:</div>
            <input className="rounded border-2 border-eBLUE300 w-full p-1 pl-2"
                name={name}
                required={required}
                //    placeholder={placeholder ? placeholder : label}
                {...props} />
        </div>
    );
};

export default LabelledInput;
