import React, {useEffect, useState} from 'react';
import LabelledInput from "../Ui/LabelledInput";
import Row from "../Ui/Row";
import LabelledTextArea from "../Ui/LabelledTextArea";
import useApi from "../../utils/custom_hooks/api-hook";
import {doPostOrder} from "../../logics/orders";
import {useCart} from "../../utils/cart-context/cart-context";
import {doGetToken} from '../../logics/company';
import {useCompany} from '../../utils/company-context/company-context';
import {ThreeDots} from 'react-loader-spinner';
import {getDomain} from '../../utils/helpers';


const Form = () => {

    const {call} = useApi();

    const {restructureCart, emptyCart} = useCart();
    const {loadCompany, company} = useCompany();
    const [processing, setProcessing] = useState(false)
    const [processed, setProcessed] = useState(false)

    useEffect(() => {

        const currentUrl = window.location.href;
        const domain = getDomain(currentUrl);

        const load = async () => {
            if (!company) {
                await call(
                    () => doGetToken(encodeURI(domain)),
                    (response) => {
                        if (response !== undefined) {
                            if (response) loadCompany(response);
                        }
                    }
                )
            }
        }
        load();
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        setProcessing(true);
        const payload = new FormData();

        payload.append('customer_name', e.currentTarget.firstname.value + " " + e.currentTarget.lastname.value)
        payload.append('customer_email', e.currentTarget.email.value)
        payload.append('customer_company', e.currentTarget.company.value)
        payload.append('customer_phone', e.currentTarget.phone.value)
        payload.append('referral_agent', e.currentTarget.referral_agent.value)
        payload.append('note', e.currentTarget.note.value)

        restructureCart().forEach((category) => {
            category.items.forEach((item, item_index) => {
                payload.append(`orderRows[${item_index}][color]`, item.color.id.toString());
                payload.append(`orderRows[${item_index}][quantity]`, item.amount.toString());
            });
        });

        // const formDataArray = Array.from(payload.entries());

        // for (const pair of formDataArray) {
        //     console.log(pair[0] + ' -> ' + pair[1]);
        // }

        await call(() => doPostOrder(payload, company.company_token),
            (res) => {
                // TODO::Handle Res
                if (Number.isInteger(res.id)) {
                    setProcessing(false)
                    setProcessed(true);
                    emptyCart()
                }
            },
            (res) => {
                console.log(res)
            }
        );

    }

    return (
        <div className="flex-1 h-full">
            {!processed && <div className="text-2xl text-eBLUE text-start font-bold mb-4">Contacts:</div>}
            {!processed ? (
                    <form className="flex flex-col gap-2" onSubmit={handleSubmit}>

                        <Row className="gap-2 w-full">
                            <LabelledInput label="First Name" name="firstname" required={true}/>
                        </Row>

                        <Row>
                            <LabelledInput label="Last Name" name="lastname" required={true}/>
                        </Row>


                        <Row className="gap-2 w-full">
                            <LabelledInput label="Company" name="company" required={true}/>
                        </Row>

                        <Row className="gap-2 w-full">
                            <LabelledInput label="Email" name="email" required={true}/>
                        </Row>

                        <Row>
                            <LabelledInput label="Phone" name="phone" required={true}/>
                        </Row>

                        <Row>
                            <LabelledInput label="Referral Agent" name="referral_agent" required={false}/>
                        </Row>

                        <Row className="gap-2 w-full">
                            <LabelledTextArea label='Notes' name='note'/>
                        </Row>

                        <button type="submit"
                                className="bg-eBLUE hover:bg-eBLUE300 active:bg-gray-500 text-white p-1 rounded mt-4 flex items-center justify-center">
                            {!processing ? ("Send") : (
                                <ThreeDots
                                    height="30"
                                    width="30"
                                    radius="10"
                                    color="#fff"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    visible={true}
                                />
                            )}
                        </button>
                    </form>
                ) :
                (
                    <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
                         role="alert">
                        <div className="flex">
                            <div>
                                <p className="font-bold">Thank you for submitting your enquiry.

                                </p>
                                <p className="text-sm">
                                    <p className="my-3">We will get back to you as soon as possible.</p>
                                    <br></br>
                                    <p className="">Please check your email inbox for further instructions or updates
                                        regarding your enquiry.</p>
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    );
};

export default Form;
