import React from 'react';
import { Link, useNavigate } from "react-router-dom";

type Props = {
    to: string,
    text: string,
    className?: string
}

const BackLink = ({ to, text, className = "" }: Props) => {

    const navigate = useNavigate();

    const goTo = () => {
        navigate(to);
    }

    return (
        <Link to={to} onClick={goTo} className={`bg-eBLUE rounded font-bold text-white p-1 px-2 cursor-pointer ${className}`}>
            {text}
        </Link>
    );
};

export default BackLink;
