import React from 'react';
import { ArticlesProvider } from "../../utils/articles-context/articles-context";
import { CompanyProvider } from "../../utils/company-context/company-context";
import { CartProvider } from "../../utils/cart-context/cart-context";

type Props = {
    children: React.ReactNode,
}

const MainProvider = ({ children }: Props) => {
    return (
        <CompanyProvider>
            <ArticlesProvider>
                <CartProvider>
                    {children}
                </CartProvider>
            </ArticlesProvider>
        </CompanyProvider>
    );
};

export default MainProvider;
