import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Payoff from "./Payoff";
import FurtherPayoffs from "./FurtherPayoffs";

type Props = {
    children?: React.ReactNode;
    payoffDisplay?: boolean;
    furtherPayoffsDisplay?: boolean;
};

const Layout = ({children, payoffDisplay = false, furtherPayoffsDisplay = false}: Props) => {
    return (
        <div className="flex flex-col min-h-screen">

            <div style={{position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000}}>
                <Header/>
            </div>

            <div style={{position: 'fixed', top: 40, left: 0, right: 0, zIndex: 1000}}>
                {payoffDisplay && <Payoff/>}

            </div>

            <div className="flex-1 flex flex-col items-center" style={{
                marginTop: '96px',
                marginBottom: '0px',
                // maxHeight: 'calc(100vh - 0px)',
                overflowY: 'hidden'
            }}>
                <div className='flex flex-col min-w-full'>
                    {furtherPayoffsDisplay && <FurtherPayoffs/>}
                </div>
                {children}
            </div>

            <div style={{position: 'fixed', bottom: 0, left: 0, right: 0}}>
                <Footer/>
            </div>

        </div>
    );
};

export default Layout;
