import {API} from "./api_config";

// const COMPANY = process.env.REACT_APP_COMPANY;


async function doGetToken(url: any) {
    return API.get(`/v1/company_token/?application_url=${url}`);
}

async function doGetCompany(token: string) {
    return API.get(`/v1/expressleather/companies/${token}`);
}


export {
    doGetCompany,
    doGetToken
};
