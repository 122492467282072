enum ArticlesActionType {
    LOAD_ARTICLES = 'LOAD_ARTICLES',
    LOAD_CATEGORIES = 'LOAD_CATEGORIES',
    SET_FILTER = 'SET_FILTER'
}

enum CompanyActionType {
    LOAD_COMPANY_TOKEN = 'LOAD_COMPANY_TOKEN',
}

enum CartActionType {
    ADD_TO_CART = 'ADD_TO_CART',
    REMOVE_FROM_CART = 'REMOVE_FROM_CART',
    LOAD_CART = 'LOAD_CART',
    SET_CURRENT_ARTICLE = 'SET_CURRENT_ARTICLE',
    CHANGE_ITEM_AMOUNT = 'CHANGE_ITEM_AMOUNT',
    EMPTY_CART = 'EMPTY_CART'
}

export {
    ArticlesActionType,
    CartActionType,
    CompanyActionType
};
