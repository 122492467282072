import { CompanyActionType } from '../reducer-types';

export const defaultState = {
    company: {},
};

function CompanyReducer(state: any, action: any) {
    switch (action.type) {
        case CompanyActionType.LOAD_COMPANY_TOKEN:
            return {
                ...state,
                company: action.company,
            };

        default:
            throw new Error('Unhandled action type: ' + action.type);
    }
}

export default CompanyReducer;
