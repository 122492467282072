import React, {useEffect, useState} from 'react';
import {doGetCompany} from '../../../logics/company';
import {useCompany} from '../../../utils/company-context/company-context';
import {doGetToken} from '../../../logics/company';
import {getDomain} from '../../../utils/helpers';
import MaxWidthDiv from "../../Ui/website_structure/MaxWidthDiv";
import {RotatingLines} from 'react-loader-spinner';
import {ICompany as Company} from "../../../utils/interfaces/ICompany";
import useApi from '../../../utils/custom_hooks/api-hook';


const imgNotFound = process.env.PUBLIC_URL + '/close-x.png';

const Payoff = () => {

    const {call, loading} = useApi();
    const {loadCompany, company} = useCompany();

    const [myCompany, setMyCompany] = useState<Company>({
        pay_off: '',
        logo_url: '',
        background_color: '#fff',
        font_color: '',
        company_name: '',
        second_pay_off: '',
        second_pay_off_color: '',
        third_pay_off: '',
        third_pay_off_color: '',
    });

    useEffect(() => {

        const currentUrl = window.location.href;
        const domain = getDomain(currentUrl);

        const load = async () => {
            if (!company) {
                await call(
                    () => doGetToken(encodeURI(domain)),
                    (response) => {
                        if (response !== undefined) {
                            if (response) loadCompany(response);
                        }
                    }
                )
            }
        }
        if (domain) {
            load();
        }
    }, []);

    useEffect(() => {

        const load = async () => {
            if (company.company_token) {
                await call(
                    () => doGetCompany(company.company_token),
                    (response) => {
                        if (response) setMyCompany(response);
                    }
                )
            }
        }

        load();

    }, [company.company_token]);


    return (
        <>
            {loading ? (

                <div className="flex flex-row items-center justify-center py-4">
                    <RotatingLines
                        strokeColor="grey"
                        strokeWidth="4"
                        animationDuration="1.5"
                        width="36"
                        visible={true}
                    />
                </div>

            ) : (

                <>
                    <div className="flex flex-col items-center justify-between px-4 py-2"
                         style={{backgroundColor: `${myCompany && myCompany.background_color ? myCompany.background_color : '#add8e6'}`}}>
                        <MaxWidthDiv className='flex justify-between items-center'>
                            <div className="flex items-center"
                                 style={{color: `${myCompany && myCompany.font_color ? myCompany.font_color : '#fff'}`}}>
                                <img style={{width: '50px'}} onError={e => {
                                    e.currentTarget.src = imgNotFound
                                }} src={`${myCompany?.logo_url !== null && myCompany?.logo_url}`}
                                     alt={`${myCompany && myCompany?.company_name}`}/>
                            </div>
                            <div className="flex items-center ml-2 font-bold text-lg">
                                <h1 style={{color: `${myCompany && myCompany.font_color ? myCompany.font_color : '#fff'}`}}>{myCompany && myCompany?.pay_off}</h1>
                            </div>
                        </MaxWidthDiv>
                    </div>
                </>
            )

            }
        </>

    );
};

export default Payoff;
