import React from 'react';
import AppNavigation from './components/app/AppNavigation';
import MainProvider from "./components/app/MainProvider";
import { Toaster } from 'react-hot-toast';

function App() {
    return (
        <>
            <MainProvider>
                <AppNavigation />
            </MainProvider>
            <Toaster
                reverseOrder={false}
                position="bottom-center"
                toastOptions={{ duration: 4000 }}
            />
        </>
    );
}

export default App;
