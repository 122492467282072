import React from 'react';
import { Article as ArticleType } from "../../types";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { item } from "../animations/animation";
import { useCart } from "../../utils/cart-context/cart-context";
import { cartHasArticle } from "../../utils/cart-utility";
import AddedToCartIcon from "../Ui/AddedToCartIcon";

type Props = {
    article: ArticleType,
}

const Article = ({ article }: Props) => {

    const { cart } = useCart();
    const navigate = useNavigate();

    const container_style = {
        backgroundImage: `url("${article.image_url}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }

    const handleClick = () => {
        navigate(`/article/${article.id}`);
    }

    return (
        <motion.div onClick={handleClick}
            variants={item}
            exit={{ opacity: 0, y: 100 }}>
            <motion.div className="bg-gray-200 rounded hover:bg-gray-300 cursor-pointer select-none h-48 shadow-lg"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.95 }}
                style={container_style}>
                <div className="w-full h-full flex flex-row items-end hover:bg-black hover:bg-opacity-30 rounded p-4">
                    <div className="flex flex-row items-center justify-between w-full">
                        <div className="font-bold text-lg text-white ">{article.name}</div>
                        {cartHasArticle(cart, article) && <AddedToCartIcon />}
                    </div>

                </div>
            </motion.div>

        </motion.div>
    );
};

export default Article;
