import React, {useEffect, useState} from 'react';
import {doGetCompany} from '../../../logics/company';
import {useCompany} from '../../../utils/company-context/company-context';
import {doGetToken} from '../../../logics/company';
import {getDomain} from '../../../utils/helpers';
import MaxWidthDiv from "../../Ui/website_structure/MaxWidthDiv";
import {RotatingLines} from 'react-loader-spinner';
import {ICompany as Company} from "../../../utils/interfaces/ICompany";
import DOMPurify from 'dompurify';

import useApi from '../../../utils/custom_hooks/api-hook';

const imgNotFound = process.env.PUBLIC_URL + '/close-x.png';

const FurtherPayoffs = () => {

    const {call, loading} = useApi();
    const {loadCompany, company} = useCompany();

    const [myCompany, setMyCompany] = useState<Company>({
        pay_off: '',
        logo_url: '',
        background_color: '#fff',
        font_color: '',
        company_name: '',
        second_pay_off: '',
        second_pay_off_color: '',
        third_pay_off: '',
        third_pay_off_color: '',
    });

    useEffect(() => {

        const currentUrl = window.location.href;
        const domain = getDomain(currentUrl);

        const load = async () => {
            if (!company) {
                await call(
                    () => doGetToken(encodeURI(domain)),
                    (response) => {
                        if (response !== undefined) {
                            if (response) loadCompany(response);
                        }
                    }
                )
            }
        }
        if (domain) {
            load();
        }
    }, []);

    useEffect(() => {

        const load = async () => {
            if (company.company_token) {
                await call(
                    () => doGetCompany(company.company_token),
                    (response) => {
                        if (response) setMyCompany(response);
                    }
                )
            }
        }
        
        load();

    }, [company.company_token]);


    return (
        <>
            {loading ? (

                <div className="flex flex-row items-center justify-center py-4">
                    <RotatingLines
                        strokeColor="grey"
                        strokeWidth="4"
                        animationDuration="1.5"
                        width="36"
                        visible={true}
                    />
                </div>

            ) : (

                <>
                    {myCompany && myCompany?.second_pay_off ?
                        <div className="flex flex-row items-center justify-center px-4 py-2"
                             style={{backgroundColor: `${myCompany && myCompany.second_pay_off_color ? myCompany.second_pay_off_color : '#add8e6'}`}}>

                            <MaxWidthDiv className=''>
                                <div className="flex flex-row justify-center ml-2 font-bold text-lg">
                                    <h1 style={{color: `${myCompany && myCompany.font_color ? myCompany.font_color : '#fff'}`}}>
                                        {myCompany && myCompany?.second_pay_off}</h1>
                                </div>
                            </MaxWidthDiv>
                        </div> : ''}
                    {myCompany && myCompany?.third_pay_off ?
                        <div className="flex flex-row items-center justify-center px-4 py-2"
                             style={{backgroundColor: `${myCompany && myCompany.third_pay_off_color ? myCompany.third_pay_off_color : '#add8e6'}`}}>

                            <MaxWidthDiv className=''>
                                <div className="flex flex-row justify-center ml-2 font-bold text-lg">
                                    <h1 style={{color: `${myCompany && myCompany.font_color ? myCompany.font_color : '#fff'}`}}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(myCompany && myCompany?.third_pay_off || ''),
                                            }}
                                        />
                                    </h1>
                                </div>
                            </MaxWidthDiv>

                        </div> : ''}
                </>
            )

            }
        </>

    );
};

export default FurtherPayoffs;
