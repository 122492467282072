import React, {useEffect, useState} from 'react';
import {useCart} from "../../utils/cart-context/cart-context";
import {CartItem} from "../../types";
import {FaMinus, FaPlus} from "react-icons/fa";
import {toast} from "react-hot-toast";

type Props = {
    item: CartItem,
    className?: string,
}

const ItemAmountCounter = ({item, className = ""}: Props) => {

    const {addToCart, changeItemAmount, currentArticle} = useCart();

    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(item.amount ?? 0);

    const Msg = () => {
        return (
            <>
                {currentArticle?.name} added to cart
            </>
        );
    };

    const handleBlur = () => {
        setEditing(false);
        changeItemAmount(item.article, item.color, value);
        // toast.success(<Msg/>);
    }

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseFloat(e.target.value);
        if (!isNaN(inputValue)) {
            setValue(parseFloat(inputValue.toFixed(1)));
        } else {
            setValue(0)
        }
    }

    useEffect(() => {
        setValue(item.amount);
    }, [item.amount])

    return (
        <div className={`flex flex-row items-center gap-2 ${className}`}>
            <button type="button"
                    title="Riduci"
                    onClick={() => changeItemAmount(item.article, item.color, item.amount - 0.5)}>
                <FaMinus className="p-1 text-xl bg-gray-200 cursor-pointer hover:bg-gray-400"/>
            </button>

            <input
                type="text"
                className="bg-transparent w-12 text-center p-0 appearance-none rounded bg-white bg-opacity-50"
                value={value}
                onChange={handleAmountChange}
                onBlur={handleBlur}
            />

            <button type="button"
                    title="Aggiungi"
                    onClick={() => changeItemAmount(item.article, item.color, item.amount + 0.5)}>
                <FaPlus className="p-1 text-xl bg-gray-200 cursor-pointer hover:bg-gray-400"/>
            </button>
        </div>
    );
};

export default ItemAmountCounter;
