import React from 'react';
import { useCart } from "../../utils/cart-context/cart-context";

const OrderRecap = () => {

    const { restructureCart } = useCart();

    return (
        <div className="flex-1 h-full rounded">
            <div className="text-2xl text-eBLUE text-start font-bold mb-4">Products:</div>
            <div>
                {restructureCart().map((category, cat_index) =>
                    <div key={cat_index} className="border-2 border-eBLUE300 w-full p-1 pl-2 rounded">
                        <div className="text-sm text-eBLUE300 mb-1 font-bold">{category.article.name}</div>

                        <div>
                            {category.items.map((item, item_index) =>
                                <div key={`item_${cat_index}_${item_index}`}>
                                    <div className="flex flex-row items-center gap-2 text-md">
                                        <div className="text-md text-eBLUE300 mb-1">Color: {item.color.name}</div>
                                        <div className="text-md text-eBLUE300 mb-1">
                                            {item.amount} mq
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {!(restructureCart().length > 0) &&
                    <div className="text-eBLUE300 text-sm">No products selected</div>
                }
            </div>
        </div >
    );
};

export default OrderRecap;
