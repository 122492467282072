import React from 'react';
import ArticlesReducer, { defaultState } from './articles-reducer';
import { Article, Category } from '../../types';
import actions from "./articles-actions";

type ArticlesProviderProps = { children: React.ReactNode };

export interface ArticlesContextData {
    articles: Article[];
    loadArticles: (articles: []) => void,
    categories: Category[];
    loadCategories: (categories: []) => void,
    filter: number | null,
    setFilter: (filter: number | null) => void
}

const ArticlesContextDefaultValue: ArticlesContextData = {
    articles: [],
    categories: [],
    filter: null,
    loadArticles: () => null,
    loadCategories: () => null,
    setFilter: () => null,
};

const ArticlesContext = React.createContext<ArticlesContextData>(ArticlesContextDefaultValue);

function useArticlesContextValue() {
    const [state, dispatch] = React.useReducer(ArticlesReducer, defaultState);

    const { articles, categories, filter } = state;

    const loadArticles = React.useCallback((articles: []) => dispatch(actions.loadArticles(articles)), []);
    const loadCategories = React.useCallback((categories: []) => dispatch(actions.loadCategories(categories)), []);
    const setFilter = React.useCallback((filter: number | null) => dispatch(actions.setFilter(filter)), []);

    return React.useMemo(() => {
        return {
            articles, loadArticles,
            categories, loadCategories,
            filter, setFilter,
        };
    }, [
        articles, loadArticles,
        categories, loadCategories,
        filter, setFilter,
    ]);
}

function ArticlesProvider({ children }: ArticlesProviderProps) {
    const value = useArticlesContextValue();
    return <ArticlesContext.Provider value={value}>{children}</ArticlesContext.Provider>;
}

function useArticles(): ArticlesContextData {
    const context = React.useContext(ArticlesContext);
    if (!context) {
        throw new Error('useArticles must be used within an ArticleProvider');
    }
    return context;
}

export { useArticles, ArticlesProvider };
