import React from 'react';
import MaxWidthDiv from "../Ui/website_structure/MaxWidthDiv";
import {useCart} from "../../utils/cart-context/cart-context";

const ArticleDetails = () => {

    const {currentArticle} = useCart();

    return (
        <MaxWidthDiv className="flex flex-row justify-start p-2">
            <div className="flex flex-col md:flex-row items-start w-full">
                {currentArticle ? <img className="p-2 mb-3 w-full md:w-1/2 object-cover" src={currentArticle.image_url}
                                       alt={currentArticle.name}/> : null}
                <div className="flex flex-col items-start justify-center w-full md:w-1/2 md:pl-8">
                    {currentArticle ? <div className="text-lg font-bold text-white">{currentArticle.name}</div> : null}
                    <p style={{whiteSpace: "pre-wrap"}} className="text-gray-600 leading-relaxed">
                        {currentArticle && currentArticle.description}
                    </p>
                </div>
            </div>
        </MaxWidthDiv>
    );
};

export default ArticleDetails;
