import React from 'react';
import MaxWidthDiv from "../Ui/website_structure/MaxWidthDiv";
import { useCart } from "../../utils/cart-context/cart-context";
import { CartCategory as Category } from "../../types";
import CategoryContainer from "./CategoryContainer";
import { useNavigate } from "react-router-dom";
import BackLink from "../Ui/BackLink";

const CartArticleList = () => {

    const { cart, restructureCart } = useCart();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/order');
    }

    return (
        <MaxWidthDiv className="py-2">
            <div className="flex flex-row items-center justify-between mb-4 px-2">
                <BackLink className='my-6' text={'Back'}
                    to={'/'} />
                <div className="text-2xl ">Selected Colors</div>
                <button className="bg-eBLUE rounded font-bold text-white p-1 px-2" onClick={handleClick}>Check Out</button>
            </div>
            {restructureCart().map((category: Category, i: number) =>
                <CategoryContainer category={category} key={i} />
            )}
            {!cart || cart.length == 0 &&
                <div className="px-2">Cart Empty</div>
            }
        </MaxWidthDiv>
    );
};

export default CartArticleList;
