import React, {useState} from 'react';
import {CartCategory} from "../../types";
import BackGroundImage from "../Ui/BackGroundImage";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import ArticleCartItem from "../Article/ArticleCartItem";
import {motion} from 'framer-motion';
import {list} from "../animations/animation";

type Props = {
    category: CartCategory
}

const CategoryContainer = ({category}: Props) => {

    const [categoryOpen, setCategoryOpen] = useState(false);

    const countItems = () => {
        const sum = category.items.map(item => item.amount)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        return sum;

    }


    return (
        <div className='px-2'>


            <div
                className="flex flex-col md:flex-row items-center justify-between shadow px-4 py-2 rounded cursor-pointer"
                onClick={() => setCategoryOpen(categoryOpen => !categoryOpen)}
            >
                <BackGroundImage image_url={category.article.image_url}
                                 className="w-full md:w-64 h-24 bg-gray-400 rounded mb-4 md:mb-0 md:mr-4"/>
                <div className="flex flex-col md:flex-1 items-center md:items-start justify-center">
                    <p className="text-eBLUE text-2xl">{category.article.name}</p>
                    <p className="flex">{category.article.description}</p>
                    <p className="flex text-right mr-4">{countItems()} mq</p>
                </div>


                <button type="button"
                        className="cursor-pointer hover:text-eBLUE text-eBLUE300 text-2xl ml-2"
                >
                    {categoryOpen
                        ? <FaChevronUp/>
                        : <FaChevronDown/>
                    }
                </button>
            </div>
            {
                categoryOpen &&
                <motion.div className="p-4 bg-gray-100" variants={list} initial="hidden" animate="visible">
                    {category.items && category.items.length > 0 &&
                        category.items.map((item, i) => <ArticleCartItem item={item} key={i}/>)
                    }
                </motion.div>
            }
        </div>
    );
};

export default CategoryContainer;
