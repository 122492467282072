import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../../pages/Home';
import Article from "../../pages/Article";
import Cart from "../../pages/Cart";
import Order from "../../pages/Order";

const AppNavigation = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="article">
                    <Route path=":id" element={<Article />} />
                </Route>
                <Route path="cart" element={<Cart />} />
                <Route path="order" element={<Order />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppNavigation;
