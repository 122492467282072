import React from 'react';

interface Props {
    label: string,
    name?: string,
    placeholder?: string,
}

const LabelledTextArea = ({ label, name = "", placeholder = "", ...props }: Props) => {
    return (
        <div className="w-full">
            <div className="text-sm text-eBLUE300 mb-1">{label}:</div>
            <textarea className="border-2 border-eBLUE300 w-full p-1 pl-2 rounded"
                name={name}
                //    placeholder={placeholder ? placeholder : label}
                {...props} />
        </div>
    );
};

export default LabelledTextArea;
