import React from 'react';
import {useCart} from "../../utils/cart-context/cart-context";
import {IndexedItem} from "../../types";
import Color from "./Color";
import {motion} from 'framer-motion';
import {list} from "../animations/animation";
import {toast} from 'react-hot-toast';
import {Link, useNavigate} from "react-router-dom";

const Colors = () => {

    const {currentArticle, addToCart} = useCart();

    const navigate = useNavigate();

    const handleClick = (color: IndexedItem) => {
        currentArticle && addToCart(currentArticle, color);
        // toast.success(<Msg/>);
    }

    const Msg = () => {
        return (
            <>
                {currentArticle?.name} added to cart
            </>
        );
    };

    return (
        <div className="flex-1">
            <div className="w-full italic text-gray-600 font-bold text-sm pt-2">
                {!currentArticle?.colors || currentArticle.colors.length == 0 ?
                    <div className="text-center">There are no colors for this article</div>
                    :
                    <div className="mx-2 mt-2 font-bold">Click on a color to add it to the cart</div>
                }
            </div>
            <motion.div variants={list}
                        initial="hidden"
                        animate="visible"
                        className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-2 py-4 px-2">
                {currentArticle?.colors && currentArticle.colors.length > 0 && currentArticle.colors.map((color, i) =>
                    <Color key={i}
                           color={color}
                           onClick={handleClick}/>
                )}
            </motion.div>
        </div>
    );
};

export default Colors;
