import axios from 'axios';

const ENDPOINT = process.env.REACT_APP_ENDPOINT;

async function get(url: string, params?: any) {
    try {
        const instance = await getInstance();
        if (params) {
            return await instance.get(ENDPOINT + url, { params: { ...params } });
        }
        return await instance.get(ENDPOINT + url);
    }
    catch (error) {
        console.log(error);
        return null;
    }
}

async function post(url: string, payload: any) {
    try {
        const instance = await getInstance();
        return await instance.post(ENDPOINT + url, payload);
    } catch (error) {
        console.log(error);
        return null;
    }
}

function getInstance() {
    return axios.create({
        baseURL: ENDPOINT,
    })
}

export const API = {
    get,
    post,
}
