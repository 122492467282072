import {CartActionType} from "../reducer-types";
import {CartItem} from "../../types";
import {cartHasItem, isSameCartItem} from "../cart-utility";

export const defaultState = {
    cart: [],
    currentArticle: null,
}

function CartReducer(state: any, action: any) {
    switch (action.type) {
        case CartActionType.LOAD_CART: {
            return {
                ...state,
                cart: action.cart
            }
        }
        case CartActionType.ADD_TO_CART: {
            const {cart} = state;
            const {article, color} = action;

            if (cartHasItem(cart, article, color)) {
                const newCart = cart.map((item: CartItem) =>
                    isSameCartItem(item, article, color)
                        ? {...item, amount: item.amount + 0.5}
                        : item
                );

                return {
                    ...state,
                    cart: [...newCart]
                }
            } else {
                const newItem: CartItem = {
                    article: article,
                    color: color,
                    amount: 0.5,
                }

                return {
                    ...state,
                    cart: [...cart, {...newItem}]
                }
            }
        }
        case CartActionType.REMOVE_FROM_CART: {
            const {cart} = state;
            const {article, color} = action;

            const newCart = cart.filter((item: CartItem) =>
                !isSameCartItem(item, article, color)
            );
            return {
                ...state,
                cart: [...newCart]
            }
        }
        case CartActionType.SET_CURRENT_ARTICLE: {
            return {
                ...state,
                currentArticle: action.article
            }
        }
        case CartActionType.EMPTY_CART: {
            return {
                ...state,
                cart: [],
            };
        }

        case CartActionType.CHANGE_ITEM_AMOUNT: {
            const {cart} = state;
            const {article, color, amount} = action;

            if (cartHasItem(cart, article, color)) {
                if (amount > 0) {
                    const newCart = cart.map((item: CartItem) =>
                        isSameCartItem(item, article, color)
                            ? {...item, amount: amount}
                            : item
                    );

                    return {
                        ...state,
                        cart: [...newCart]
                    }
                } else {
                    const newCart = cart.filter((item: CartItem) =>
                        !isSameCartItem(item, article, color)
                    );
                    return {
                        ...state,
                        cart: [...newCart]
                    }
                }

            } else {
                return {
                    ...state,
                }
            }
        }
        default:
            throw new Error('Unhandled action type: ' + action);
    }
}

export default CartReducer;
